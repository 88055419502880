<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="box mx-auto"
      :elevation="isCurrentCompany ? 10 : 1"
      :color="color"
      :class="{ 'current-company': isCurrentCompany, 'on-hover': hover }"
      dark
      max-width="400"
      @click="$emit('selectedCompany', company)"
    >
      <span class="font-weight-light">{{ company.name }}</span>
    </v-card></v-hover
  >
</template>
<script>
  export default {
    props: ['company', 'currentCompany'],
    data() {
      return {}
    },
    computed: {
      color() {
        switch (this.company.level_id) {
          case 1:
            return '#2F5597'

          case 2:
            return '#00B0F0'

          case 3:
            return '#008EB9'

          default:
            return '#26c6da'
        }
      },
      isCurrentCompany() {
        if (this.company.id === this.currentCompany.id) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box {
    cursor: pointer;
    span {
      font-size: small;
    }
  }
  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 5px;
    justify-content: center;
  }
  .current-company {
    background-color: #000;
  }
  .v-application .elevation-10 {
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 40%), 0px 10px 14px 1px rgb(0 0 0 / 40%),
      0px 4px 18px 3px rgb(0 0 0 / 20%) !important;
  }
  .on-hover {
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 20%),
      0px 4px 18px 3px rgb(0 0 0 / 10%) !important;
  }
</style>
